<template>
  <div>
    <br class="d-none d-md-block" />

    <v-row v-if="searchable" class="mb-4">
      <v-col cols="12" md="4">
        <v-text-field
          v-model="searchKeyword"
          solo
          flat
          hide-details
          :label="$t('searchKeywordPlaceholder')"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-select
          v-model="statusFilter"
          :items="statusOptions"
          multiple
          chips
          solo
          flat
          hide-details
          clearable
          deletable-chips
          :label="$t('feedbacks.filterByStatus')"
        >
        </v-select>
      </v-col>

      <v-col cols="12" md="4">
        <v-select
          v-model="processTypeFilter"
          :items="processTypeOptions"
          multiple
          chips
          solo
          flat
          hide-details
          clearable
          deletable-chips
          :label="$t('feedbacks.filterByProcessType')"
        >
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div class="table feedbacks-overview">
          <div class="table-header d-none d-md-flex">
            <v-row class="d-flex">
              <v-col cols="12" md="1" class="caption">{{
                $t('feedbacks.type_short')
              }}</v-col>

              <v-col
                cols="12"
                :md="employeeColumn === 'recipient' ? 2 : 3"
                class="caption"
                >{{ $t('feedbacks.status') }}</v-col
              >

              <v-col
                cols="12"
                :md="employeeColumn === 'recipient' ? 2 : 3"
                class="caption"
              >
                <template v-if="employeeColumn === 'recipient'">
                  {{ $t('employees') }}
                </template>
                <template v-else>
                  {{ $t('feedbacks.processPartner') }}
                </template>
              </v-col>

              <v-col
                cols="12"
                v-if="employeeColumn === 'recipient'"
                md="3"
                class="caption"
              >
                {{ $t('employeeDetails.supervisor') }}
              </v-col>

              <v-col
                cols="12"
                :md="employeeColumn === 'recipient' ? 3 : 4"
                class="caption"
                >{{ $t('feedbacks.nextStep') }}</v-col
              >

              <v-col cols="12" md="1"></v-col>
            </v-row>
          </div>

          <div
            v-if="feedbacks && !loading && feedbacks.length > 0"
            class="overview-body table-body"
          >
            <br class="d-none d-md-block" />
            <template v-if="visibleFeedbacks && visibleFeedbacks.length > 0">
              <template v-for="feedback in visibleFeedbacks">
                <feedback-item
                  :feedback="feedback"
                  :key="`feedback_table_item_${feedback.id}`"
                  :employeeColumn="employeeColumn"
                  :expanded="itemIsExpanded(feedback)"
                  :keyword="searchKeyword"
                  @process-created="$emit('process-created')"
                  @expanded="itemExpanded(feedback)"
                  @collapsed="itemCollapsed(feedback)"
                ></feedback-item>
              </template>
            </template>
            <v-row v-else>
              <v-col cols="12">
                <v-alert text type="info">
                  {{ $t('feedbacks.noActiveProcessFound') }}
                </v-alert>
              </v-col>
            </v-row>
          </div>
          <div v-else-if="loading" class="mt-4">
            <v-row>
              <v-col cols="12">
                <v-progress-linear indeterminate></v-progress-linear>
              </v-col>
            </v-row>
          </div>
          <div v-else class="mt-4">
            <v-row>
              <v-col cols="12">
                <v-alert text type="info">
                  {{ $t('feedbacks.noActiveProcess') }}
                </v-alert>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row v-show="numPages > 1">
      <v-col cols="12">
        <div class="text-center">
          <v-pagination
            v-model="pageIndex"
            :length="numPages"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import feedbackItem from '@/components/feedbacks/feedback-table-item';

export default {
  props: {
    feedbacks: {
      type: Array,
      required: false,
      default: () => []
    },

    employeeColumn: {
      type: String,
      required: false,
      default: 'recipient'
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    expanded: {
      type: Boolean,
      required: false,
      default: false
    },

    searchable: {
      type: Boolean,
      required: false,
      default: false
    },

    statusQuery: {
      type: Array,
      required: false,
      default: () => []
    },

    processTypeQuery: {
      type: Array,
      required: false,
      default: () => []
    },

    keywordQuery: {
      type: String,
      required: false,
      default: ''
    },

    context: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: () => ({
    pageIndex: 1,
    itemsPerPage: 15,
    searchKeyword: '',
    statusFilter: [],
    processTypeFilter: [],
    storedState: {
      expandedItems: []
    },
    initializing: true
  }),

  watch: {
    statusQuery() {
      this.statusFilter = this.statusQuery;
    },

    processTypeQuery() {
      this.processTypeFilter = this.processTypeQuery;
    },

    keywordQuery() {
      this.searchKeyword = this.keywordQuery;
    },

    numPages() {
      if (this.numPages < this.pageIndex) {
        this.pageIndex = Math.max(1, this.numPages);
      }
    },

    searchKeyword() {
      this.storedState.keyword = this.searchKeyword;
      this.saveFilterState();
    },

    statusFilter() {
      this.storedState.status = this.statusFilter;
      this.saveFilterState();
    },

    processTypeFilter() {
      this.storedState.processType = this.processTypeFilter;
      this.saveFilterState();
    }
  },

  computed: {
    numPages() {
      return Math.ceil(this.sortedFeedbacks.length / this.itemsPerPage);
    },

    filteredFeedbacks() {
      const kw = this.searchKeyword.toLowerCase();
      const states = this.statusFilter?.map((x) => x.statusId);

      const types = this.statusFilter?.map((x) => x.processType);
      const processTypes = this.processTypeFilter;

      if (kw === '' && !states && !processTypes) {
        return this.feedbacks;
      }

      return this.feedbacks.filter((x) => {
        if (
          states &&
          states.length > 0 &&
          (!states.includes(x.statusId) || !types.includes(x.processType))
        ) {
          return false;
        }

        if (
          processTypes &&
          processTypes.length > 0 &&
          !processTypes.includes(x.processType)
        ) {
          return false;
        }

        return (
          x.recipientName.toLowerCase().indexOf(kw) > -1 ||
          x.donorName.toLowerCase().indexOf(kw) > -1 ||
          x.mentorName?.toLowerCase().indexOf(kw) > -1
        );
      });
    },

    sortedFeedbacks() {
      if (!this.filteredFeedbacks || this.filteredFeedbacks.length === 0) {
        return [];
      }

      return [...this.filteredFeedbacks].sort((a, b) => {
        if (
          a.recipientLastName.toLowerCase() < b.recipientLastName.toLowerCase()
        ) {
          return -1;
        } else if (
          a.recipientLastName.toLowerCase() > b.recipientLastName.toLowerCase()
        ) {
          return 1;
        } else {
          return 0;
        }
      });
    },

    statusOptions() {
      if (!this.feedbacks) {
        return [];
      }

      const options = [];
      var validFeedbacks = this.feedbacks;
      if (this.processTypeFilter?.length > 0) {
        validFeedbacks = validFeedbacks.filter((x) =>
          this.processTypeFilter.includes(x.processType)
        );
      }

      for (const feedback of validFeedbacks) {
        var option = options.find(
          (x) =>
            x.processType === feedback.processType &&
            x.statusId === feedback.statusId
        );
        if (!option) {
          options.push({
            processType: feedback.processType,
            statusId: feedback.statusId
          });
        }
      }

      return options.map((x) => {
        return {
          value: x,
          text: `${this.$t(
            `feedbacks.processTypes.${x.processType}`
          )} - ${this.localizeStatus(x.processType, x.statusId)}`
        };
      });
    },

    processTypeOptions() {
      if (!this.feedbacks) {
        return [];
      }

      const processTypeSource =
        this.statusFilter?.length > 0 ? this.statusFilter : this.feedbacks;
      const processTypes = [
        ...new Set(processTypeSource.map((x) => x.processType))
      ];

      return processTypes.map((x) => {
        return {
          value: x,
          text: this.$t(`feedbacks.processTypes.${x}`)
        };
      });
    },

    visibleFeedbacks() {
      const startItem = (this.pageIndex - 1) * this.itemsPerPage;
      const endItem = Math.min(
        startItem + this.itemsPerPage,
        this.sortedFeedbacks.length
      );
      return this.sortedFeedbacks.slice(startItem, endItem);
    }
  },

  mounted() {
    this.statusFilter = this.statusQuery;
    this.processTypeFilter = this.processTypeQuery;
    this.searchKeyword = this.keywordQuery;

    this.setFilterSettingsFromSession();

    setTimeout(() => {
      this.initializing = false;
    }, 500);
  },

  methods: {
    saveFilterState() {
      if (this.initializing) {
        return;
      }

      const data = JSON.stringify(this.storedState);
      sessionStorage.setItem(`${this.context}_filter_state`, data);
    },

    setFilterSettingsFromSession() {
      // parse filter settings from session storage and check if
      // current state needs to be overwritten
      const sessionData = sessionStorage.getItem(
        `${this.context}_filter_state`
      );

      if (sessionData) {
        const filterSettings = JSON.parse(sessionData);
        if (filterSettings.status) {
          this.statusFilter = filterSettings.status;
        }

        if (filterSettings.processType) {
          this.processTypeFilter = filterSettings.processType;
        }

        if (filterSettings.keyword) {
          this.searchKeyword = filterSettings.keyword;
        }

        if (filterSettings.expandedItems) {
          this.storedState.expandedItems = filterSettings.expandedItems;
        }
      }
    },

    itemExpanded(feedback) {
      const itemId = feedback.id;
      this.storedState.expandedItems.push(itemId);

      this.saveFilterState();
    },

    itemCollapsed(feedback) {
      const itemId = feedback.id;
      this.storedState.expandedItems = this.storedState.expandedItems.filter(
        (x) => x !== itemId
      );

      this.saveFilterState();
    },

    itemIsExpanded(feedback) {
      if (this.expanded) {
        return true;
      }

      return this.storedState.expandedItems.includes(feedback.id);
    }
  },

  components: {
    feedbackItem
  }
};
</script>

<style scoped lang="scss">
.table {
  margin-left: -3px;
  margin-right: -3px;
}

.table strong {
  color: #000;
}
</style>
