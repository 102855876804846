var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"feedback-row",class:{ 'bottom-border': _vm.itemExpanded }},[_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('p',{staticClass:"d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"},[_c('strong',[_vm._v(_vm._s(_vm.$t('feedbacks.type')))])]),_c('strong',[_vm._v(_vm._s(_vm.$t(("feedbacks.processTypes." + (_vm.feedback.processType)))))])]),_c('v-col',{attrs:{"cols":"12","md":_vm.employeeColumn === 'recipient' ? 2 : 3}},[_c('p',{staticClass:"d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"},[_c('strong',[_vm._v(_vm._s(_vm.$t('feedbacks.status')))])]),_c('v-chip',{attrs:{"label":"","color":_vm.getStatusColor(_vm.feedback.statusId, _vm.feedback.processType)}},[_vm._v(" "+_vm._s(_vm.localizeStatus(_vm.feedback.processType, _vm.feedback.statusId))+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":_vm.employeeColumn === 'recipient' ? 2 : 3}},[_c('p',{staticClass:"d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"},[_c('strong',[_vm._v(_vm._s(_vm.$t('feedbacks.processPartner')))])]),(_vm.employeeColumn === 'recipient')?[_c('router-link',{attrs:{"to":{
          name: 'EmployeeDetails',
          params: { shorthand: _vm.feedback.recipientShorthand }
        }}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.highlight(_vm.feedback.recipientName, _vm.keyword))}})])]:[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.highlight(_vm.feedback.donorName, _vm.keyword))}})]],2),(_vm.employeeColumn === 'recipient')?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('p',{staticClass:"d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"},[_c('strong',[_vm._v(_vm._s(_vm.$t('employeeDetails.supervisor')))])]),(_vm.feedback.mentorAvailable)?[_c('router-link',{attrs:{"to":{
          name: 'EmployeeDetails',
          params: { shorthand: _vm.feedback.mentorShorthand }
        }}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.highlight(_vm.feedback.mentorName, _vm.keyword))}})]),_c('div',{staticClass:"mt-2"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(_vm.$t('feedbacks.mentor'))+": "),_c('router-link',{attrs:{"to":{
            name: 'EmployeeDetails',
            params: { shorthand: _vm.feedback.donorShorthand }
          }}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.highlight(_vm.feedback.donorName, _vm.keyword))}})])],1)]:[_c('router-link',{attrs:{"to":{
          name: 'EmployeeDetails',
          params: { shorthand: _vm.feedback.donorShorthand }
        }}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.highlight(_vm.feedback.donorName, _vm.keyword))}})])]],2):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":_vm.employeeColumn === 'recipient' ? 3 : 4}},[_c('p',{staticClass:"d-xs-block d-md-none ma-0 caption text-uppercase grey--text text--darken-1"},[_c('strong',[_vm._v(_vm._s(_vm.$t('feedbacks.nextStep')))])]),_vm._v(" "+_vm._s(_vm.localizeStatusDescription(_vm.feedback.processType, _vm.feedback.statusId))+" "),(_vm.feedback.mentorAvailable)?_c('div',{staticClass:"mt-4"}):_vm._e()]),_c('v-col',{staticClass:"expandable d-flex justify-center",attrs:{"cols":"12","md":"1"},on:{"click":function($event){_vm.itemExpanded = !_vm.itemExpanded}}},[_c('v-icon',[_vm._v(_vm._s(_vm.expanderIcon))])],1),_c('v-expand-transition',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.itemExpanded),expression:"itemExpanded"}],staticClass:"expanded-content",class:_vm.initializingClass,attrs:{"cols":"12"}},[_c('process-details',{attrs:{"process":_vm.feedback}}),(_vm.canCreateProcess)?_c('div',{staticClass:"d-flex align-center pt-4"},[_c('v-btn',{staticClass:"mr-8",attrs:{"loading":_vm.processCreating},on:{"click":_vm.createNewProcess}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('feedbacks.createWithType', { processType: _vm.$t( ("feedbacks.processTypes." + (_vm.feedback.processType)) ) }))+" ")],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.$t(("feedbacks.createProcessInfo." + (_vm.feedback.processType))) !== ''
          ),expression:"\n            $t(`feedbacks.createProcessInfo.${feedback.processType}`) !== ''\n          "}]},[_vm._v(" "+_vm._s(_vm.$t(("feedbacks.createProcessInfo." + (_vm.feedback.processType))))+" ")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }